// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-category-js": () => import("/zeit/783a7ddd/src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-project-js": () => import("/zeit/783a7ddd/src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-pages-404-js": () => import("/zeit/783a7ddd/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("/zeit/783a7ddd/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-gallery-js": () => import("/zeit/783a7ddd/src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("/zeit/783a7ddd/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

